import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "InstarVision Remote Access",
  "path": "/Frequently_Asked_Question/InstarVision_Remote_Access/",
  "dateChanged": "2021-07-12",
  "author": "Mike Polinowski",
  "excerpt": "I have more than 3 simultaneous accesses to my camera and am reaching the limit. How can I use the InstarVision Windows software to get indirect access to the camera?",
  "image": "./FAQ_SearchThumb.png",
  "social": "/images/Search/FAQ_SearchThumb.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_FAQs_white.webp",
  "chapter": "FAQs",
  "faq": "HD Cameras"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <SEOHelmet title='FAQs - InstarVision Remote Access' dateChanged='2021-07-12' author='Mike Polinowski' tag='INSTAR IP Camera' description='I have more than 3 simultaneous accesses to my camera and am reaching the limit. How can I use the InstarVision Windows software to get indirect access to the camera?' image='/images/Search/FAQ_SearchThumb.png' twitter='/images/Search/FAQ_SearchThumb.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationDE={props.location.pathname} locationEN='/en/Frequently_Asked_Question/InstarVision_Remote_Access/' locationFR='/fr/Frequently_Asked_Question/InstarVision_Remote_Access/' crumbLabel="FAQs" mdxType="BreadCrumbs" />
    <h1 {...{
      "id": "instarvision-remote-access",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#instarvision-remote-access",
        "aria-label": "instarvision remote access permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`InstarVision Remote Access`}</h1>
    <p><strong parentName="p">{`Q`}</strong>{`: I have more than 3 simultaneous accesses to my camera and am reaching the limit. How can I use the InstarVision Windows software to get indirect access to the camera?`}</p>
    <p><strong parentName="p">{`A`}</strong>{`: You can use the `}<a parentName="p" {...{
        "href": "/en/Software/Windows/InstarVision/"
      }}>{`InstarVision 2 for Windows`}</a>{` as "Master Software", which then forwards the video stream of your camera to other instances of the software. So if you have more than 3 instances of the software running on different Windows PCs, designate one of them as the master and create a user there. By default, an admin user is already created here. However, it makes sense to create a user with limited rights who only has access to the required cameras:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/bbf0f5222ce3d7e8800d2eea9982d25f/8ea22/iv-remote-access_01.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.086956521739125%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAIAAADwazoUAAAACXBIWXMAAA7DAAAOwwHHb6hkAAACoklEQVQoz2Pwy2hQMXGydnB08vR28vC2dXGzcXY1NTX1cHV19/RydXP19PLx9PLy8PTw8/e1sbPp6e29evny5EmTbt24weDasjM4IGJRefLGvobN/fVLm4om5US7WZslp6aUVjbk5RfPnDl9zsxps2ZOnDS5q7apvHdiz+pVqydM6N+4YT1DWJDP6QjV/5lqP1JVf6Wq/khT+5+tVudt5h0cmZjckpyc3tXROmfG1FnTJ0yb2tPaWd/e3bZs+YqVq1evWLGSISrA/1yY8v9M5T8pSv9SlH6nKP3PVs10VFfQMU5O6w8M9G+sr508acLkid39fa2NLZV1jVVTJ/XPmDxhxtTJDDatzYkRHgtywheXJy8pT5mdE1Uf4bjYyqPU2SM4PtbdxaG2pqKvp72vp6Wrs76yOr+xo6173rqOBZvmr93BYBYZIaiorKSsrKyurqymrqSiKqmoECCv6aymIa+mJiUjq6qubmRkZGhkZGltoa6lXt3Q/Prv/8svfz368JshLTnV0c4+ODjQ39/H29vF28slwNfd099TWlGZgYGZkYmJAQZYWVkZGBiSk5L+/fr++tnjNy+fMfRPmbp194EtOw5s2LZ78rTeSZO7u9trp0/sNDMzZ2BgYAJrZmQEIRYWFgYGhtTUtB8/f126fOX+/QcMNdU12/ceXbJ695ote1KzMsoqivMS/FOifLV1dRkYGNg5uNnYOFlZ2VnZOLi5+RgYGDKzMv///3/39q13b94wlJYXpaQlz1y0YcGanRnp8UV5cfHJcV6B/orKSmCncrCwsDEzszKzsHFwcIGcnZb+/d//24+evPv0hSGnINvW1qS2tqKutSMj1jc/zrWipqK9u0teURHsYEa4n5mYmRkYGEwDIhtOPKzcdXHq0WsATX8E4DYxn+8AAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/bbf0f5222ce3d7e8800d2eea9982d25f/e4706/iv-remote-access_01.avif 230w", "/en/static/bbf0f5222ce3d7e8800d2eea9982d25f/d1af7/iv-remote-access_01.avif 460w", "/en/static/bbf0f5222ce3d7e8800d2eea9982d25f/7f308/iv-remote-access_01.avif 920w", "/en/static/bbf0f5222ce3d7e8800d2eea9982d25f/fab00/iv-remote-access_01.avif 1023w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/bbf0f5222ce3d7e8800d2eea9982d25f/a0b58/iv-remote-access_01.webp 230w", "/en/static/bbf0f5222ce3d7e8800d2eea9982d25f/bc10c/iv-remote-access_01.webp 460w", "/en/static/bbf0f5222ce3d7e8800d2eea9982d25f/966d8/iv-remote-access_01.webp 920w", "/en/static/bbf0f5222ce3d7e8800d2eea9982d25f/da35d/iv-remote-access_01.webp 1023w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/bbf0f5222ce3d7e8800d2eea9982d25f/81c8e/iv-remote-access_01.png 230w", "/en/static/bbf0f5222ce3d7e8800d2eea9982d25f/08a84/iv-remote-access_01.png 460w", "/en/static/bbf0f5222ce3d7e8800d2eea9982d25f/c0255/iv-remote-access_01.png 920w", "/en/static/bbf0f5222ce3d7e8800d2eea9982d25f/8ea22/iv-remote-access_01.png 1023w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/bbf0f5222ce3d7e8800d2eea9982d25f/c0255/iv-remote-access_01.png",
              "alt": "InstarVision Windows Fernzugriff",
              "title": "InstarVision Windows Fernzugriff",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <blockquote>
      <p parentName="blockquote">{`The `}<strong parentName="p">{`default login`}</strong>{` of the `}<strong parentName="p">{`admin`}</strong>{` user is `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`admin`}</code>{` / `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`instar`}</code>{`.`}</p>
    </blockquote>
    <p>{`Now go to one of the other PCs and add a `}<strong parentName="p">{`Remote Instance`}</strong>{` as a camera:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/7fe19691f50d6a52a375492866a114c9/2bef9/iv-remote-access_02.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "57.826086956521735%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAIAAADtbgqsAAAACXBIWXMAAA7DAAAOwwHHb6hkAAACyklEQVQoz2NQM3Eyt7axc3Gzc3G1dnS2dXYxs7B0trdxcfV0dHJwcnZ2cXV1cXF2dXP19HR3cHI6eODA7u1bli5acOjQCQZ7v+TegsSlEzqWT2yf217XVZQe5u7k7uZc19icnJLd0tI4dcqE6dP6pkzp6uiqr6grmTp9Wm1rb01T25w5ixj2hmr8z9b8lqbxI13jW7rm/0z1HTEmLm6uaVlNYZE5VVVFUyb1zZrWP2Na74SJ7U1tNXv275249siRmy8vXLzGcDhE9X+G8u9kxb8pir9TlP5nKm+J1JdUlQ2N6giJyMpMj5vQ1zt1cu+kiR3d3Y3V9aXnLpx9cPfOu9evLl+9zVAS7rQ7x2d3afi+8ogdBUErkh3neTsW6pjFpee5unpkpCX0dndM6Gvv625qb68tqcy7dOXSfzC4dPkmg7ixmZmJmYWNraWNrZmFpZqhQYimcZ2upbGVqaaWpompsZeXR0CAb2Cgb3Cov7uX68lTp8B6/128fJNBXUnRQE9bT1tNT1tNX0fNzEDb0FBLUVONhZWLhZWDgZGJgYGRgYGRkYmZg5OTlYll+7btYL1/L1++wTBj3qJlazau3Lxz0rQJfRM6Orqa+/s6UlKSGUA6GBmQAISzdc++//////3//9zluwxrt+5r7pi658j5uqa62qa6xoqssvSwqMhwBgYGVjYOVlYOFhY2VhY2RlZ2WTbOUA7ea+tWvbxz5sfzGz+fX2XIyM/Lr+xav+tUZl5OUlpcXna8vb2lrYMdAwMjGzsnGzsXKxsHOxsHAxuHJidPNY/Q8Yld5/ctubp32f1j6xicXSybmhumzV9ZWphWneFVUVXY3NXt5umF6WwIyC8tW7Zq3Zz5i3onz2bQNTENDA+JSsn08ffxcrX2DghIyy81t3fl4hcVEJPmF5XiF5UEIykBUSkBEcny8sqFC+bPmjl94qRpAELvHdjXJXXnAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/7fe19691f50d6a52a375492866a114c9/e4706/iv-remote-access_02.avif 230w", "/en/static/7fe19691f50d6a52a375492866a114c9/d1af7/iv-remote-access_02.avif 460w", "/en/static/7fe19691f50d6a52a375492866a114c9/7f308/iv-remote-access_02.avif 920w", "/en/static/7fe19691f50d6a52a375492866a114c9/e3723/iv-remote-access_02.avif 1024w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/7fe19691f50d6a52a375492866a114c9/a0b58/iv-remote-access_02.webp 230w", "/en/static/7fe19691f50d6a52a375492866a114c9/bc10c/iv-remote-access_02.webp 460w", "/en/static/7fe19691f50d6a52a375492866a114c9/966d8/iv-remote-access_02.webp 920w", "/en/static/7fe19691f50d6a52a375492866a114c9/a9a89/iv-remote-access_02.webp 1024w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/7fe19691f50d6a52a375492866a114c9/81c8e/iv-remote-access_02.png 230w", "/en/static/7fe19691f50d6a52a375492866a114c9/08a84/iv-remote-access_02.png 460w", "/en/static/7fe19691f50d6a52a375492866a114c9/c0255/iv-remote-access_02.png 920w", "/en/static/7fe19691f50d6a52a375492866a114c9/2bef9/iv-remote-access_02.png 1024w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/7fe19691f50d6a52a375492866a114c9/c0255/iv-remote-access_02.png",
              "alt": "InstarVision Windows Fernzugriff",
              "title": "InstarVision Windows Fernzugriff",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{` Enter the IP address of the server on which the master version of InstarVision is running. The port can be freely selected - `}<strong parentName="p">{`but make sure that the port is also released in the Windows firewall`}</strong>{`. If the master server is located in an `}<strong parentName="p">{`external network`}</strong>{`, you must create a `}<strong parentName="p">{`port forwarding`}</strong>{` on the server in the Internet router - just as is necessary for `}<a parentName="p" {...{
        "href": "/en/Internet_Access/Port_Forwarding/"
      }}>{`remote access to a camera`}</a>{`. The login is the user you created in the master software in the previous step:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/c59f0a73b65df7c04e82c3bef0b01e75/e088a/iv-remote-access_03.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "46.086956521739125%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAIAAAC9o5sfAAAACXBIWXMAAA7EAAAOxAGVKw4bAAACMElEQVQozwElAtr9ACZPcyQvN0BGSEZNUERLTT5FRzY/QjIxLkhISEhKSkhIR01OTk5PT09QTU9RTj5APpaXlru2tIB8edDOzgAyaZeGWkm8g2rEjXbEjna4gGegZUtFNzBobW1maXZvb3CUlI2dnJWfnpSXmo+GjIGVl5KDk6B/kJ3Av78ATzou8GQm+2Qi9mIi92Eh+Gkr+YJQgGdZcXeAfH2YgoB+n52ZraunqKaglJWNf4R7s7WwrsDOuczb+Pj4AFA+N+1kKPpnJ/doKvhgHvOEU/D2+fDv7vPy8/Lz8+7u7vT09Pj49+Pj4ZKTjHp+d7CzsfLt6/Pw7fT09AAfdXNvV0qRb2GXeGqQbmCgh3zx8fHq6ur29vby8vLn5ufw8PD19fXg4d+PkId0d2y2urX////6/Pz29fUAFjExFRobGBoZHB8eEBMSR0lI+fn47u7u7u/v8PLz6uzs8PHy+fv8zc7PMjQ0FxobkZOU////9/f39fT0AFZTV1JRUWFhYVtbWE5OR3d2cfDv8Onp6fT08/Xt7Pz19Pz59////+Hf2ltcUEJDNqKkn/////z8/Pj39wCRmJulqa6zucSyubuKjYWpqqX//////v76/v/A6vW75vLd9vvF8/+93OWPi3J0d1yrraLs6+3n6Off398AfX9+YGRtlJ2uo6qvW19chIR+o52UmZeTnKKifqPJmsP1iqKxdpqbiqGegHxmb3JZhYh9p6aooaCgsLCwgYJNnmC9U9QAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/c59f0a73b65df7c04e82c3bef0b01e75/e4706/iv-remote-access_03.avif 230w", "/en/static/c59f0a73b65df7c04e82c3bef0b01e75/d1af7/iv-remote-access_03.avif 460w", "/en/static/c59f0a73b65df7c04e82c3bef0b01e75/7f308/iv-remote-access_03.avif 920w", "/en/static/c59f0a73b65df7c04e82c3bef0b01e75/9e7d9/iv-remote-access_03.avif 1015w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/c59f0a73b65df7c04e82c3bef0b01e75/a0b58/iv-remote-access_03.webp 230w", "/en/static/c59f0a73b65df7c04e82c3bef0b01e75/bc10c/iv-remote-access_03.webp 460w", "/en/static/c59f0a73b65df7c04e82c3bef0b01e75/966d8/iv-remote-access_03.webp 920w", "/en/static/c59f0a73b65df7c04e82c3bef0b01e75/3a6ad/iv-remote-access_03.webp 1015w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/c59f0a73b65df7c04e82c3bef0b01e75/81c8e/iv-remote-access_03.png 230w", "/en/static/c59f0a73b65df7c04e82c3bef0b01e75/08a84/iv-remote-access_03.png 460w", "/en/static/c59f0a73b65df7c04e82c3bef0b01e75/c0255/iv-remote-access_03.png 920w", "/en/static/c59f0a73b65df7c04e82c3bef0b01e75/e088a/iv-remote-access_03.png 1015w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/c59f0a73b65df7c04e82c3bef0b01e75/c0255/iv-remote-access_03.png",
              "alt": "InstarVision Windows Fernzugriff",
              "title": "InstarVision Windows Fernzugriff",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/FAQs/"
        }}>{`FAQs`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9420WQHD/"
            }}>{`IN-9420 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9408WQHD/"
            }}>{`IN-9408 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8415WQHD/"
            }}>{`IN-8415 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8403WQHD/"
            }}>{`IN-8403 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8401WQHD/"
            }}>{`IN-8401 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9020FHD/"
            }}>{`IN-9020 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9010FHD/"
            }}>{`IN-9010 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9008FHD/"
            }}>{`IN-9008 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8015FHD/"
            }}>{`IN-8015 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8003FHD/"
            }}>{`IN-8003 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8001FHD/"
            }}>{`IN-8001 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-7011HD/"
            }}>{`IN-7011 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-6014HD/"
            }}>{`IN-6014 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-6012HD/"
            }}>{`IN-6012 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-6001HD/"
            }}>{`IN-6001 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-5907HD/"
            }}>{`IN-5907 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-5905HD/"
            }}>{`IN-5905 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/VGA_Series/"
            }}>{`VGA Series`}</a></li>
        </ul>
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      